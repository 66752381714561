import {useEffect} from 'react'
import queryString from 'query-string'
import {PageRendererProps, navigate} from 'gatsby'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import SetPasswordForm from 'src/components/SetPasswordForm'
import {Header1} from 'src/components/text'
import {Box} from 'src/components/Box'
import {useToastAction} from 'src/context/ToastContext'
import {simpleToast} from 'src/components/ToastTemplates'
import {localPaths} from 'src/urls'

const SetPassword = ({location}: PageRendererProps) => {
  const {addToast} = useToastAction()
  const {reset_password_token} = queryString.parse(location.search)

  useEffect(() => {
    if (!reset_password_token) {
      navigate(localPaths.resetPassword)
    }
  }, [reset_password_token])

  const handleSuccess = () => {
    addToast({body: simpleToast('Password updated!')})
    navigate(localPaths.home)
  }

  return (
    <Layout narrow>
      <SEO title="Reset Password" />
      <Box pt={4} mb={3}>
        <Header1>Reset your password</Header1>
      </Box>

      <SetPasswordForm
        resetToken={reset_password_token as string}
        onSuccess={handleSuccess}
      />
    </Layout>
  )
}

export default SetPassword
