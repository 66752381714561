import {Form} from 'react-final-form'
import {FORM_ERROR} from 'final-form'
import mapValues from 'lodash/mapValues'

import Button from 'src/components/Button'
import {setNewPassword} from 'src/gs-api'
import {ErrorMessage} from 'src/components/form/input'
import {required} from 'src/form/validation'
import useViewer from 'src/hooks/useViewer'

import TextField from './form/TextField'
import {Box, Flex} from './Box'

interface FormValues {
  password: string
  password_confirmation: string
}

interface Props {
  resetToken: string
  onSuccess: () => void
}

const SetPasswordForm = ({resetToken, onSuccess}: Props) => {
  const {loading, refetch} = useViewer()

  const onSubmit = ({password, password_confirmation}: FormValues) =>
    setNewPassword({
      student: {
        password,
        password_confirmation,
        reset_password_token: resetToken,
      },
    }).then(async (result) => {
      if (result && 'errors' in result) {
        if ('reset_password_token' in result.errors) {
          return {
            [FORM_ERROR]: 'Reset password token is invalid',
          }
        }
        return mapValues(result.errors, (e) => e.join(' '))
      }

      // NOTE: refetch to get new session
      await refetch()

      onSuccess()
    })

  return (
    <Form
      initialValues={{
        password: '',
        password_confirmation: '',
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        submitError,
        submitting,
        pristine,
        valid,
        dirtySinceLastSubmit,
      }) => (
        <form
          css={{display: 'flex', flexDirection: 'column'}}
          onSubmit={handleSubmit}
        >
          <TextField
            validate={required}
            label="New Password"
            name="password"
            type="password"
          />
          <Box mt={3}>
            <TextField
              validate={required}
              label="Confirm New Password"
              name="password_confirmation"
              type="password"
            />
          </Box>

          {submitError && <ErrorMessage>{submitError}</ErrorMessage>}
          <Flex mt={3} flexDirection="column">
            <Button
              busy={submitting}
              disabled={
                loading ||
                submitting ||
                pristine ||
                (!valid && !dirtySinceLastSubmit)
              }
              type="submit"
            >
              Reset
            </Button>
          </Flex>
        </form>
      )}
    </Form>
  )
}

export default SetPasswordForm
